@import './../../../theme/theme';

.giftContainer {
  position: fixed;
  display: flex;
  top: 100px;
  left: 0;
  right: 0;
  width: fit-content;
  min-width: 240px;
  max-width: 100%;
  margin: auto;
  background-color: $backgroundColor;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px 20px;
  z-index: 2;
  //   border: 2px solid #d1d1d1;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  text-align: center;
}

.giftTo {
  min-width: 150px;
  color: $primaryFontColor;
}

.giftTo a {
  color: $primaryColor;
  cursor: pointer;
}

.giftTo a:hover {
  text-decoration: underline;
}

.selectProject {
  font-size: 20px;
  min-width: 150px;
  color: $primaryFontColor;
}

.closeButton {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
}

.closeButton svg {
  width: 12px;
}

:export {
  primaryFontColor: $primaryFontColor;
}

@import '../../../../theme/theme';

.darkmodeLabel {
  background-color: rgba($color: #b2b2b2, $alpha: 0.4);
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  height: 12px;
  width: 24px;
  transform: scale(1.5);
  margin-right: 18px;
  svg > path {
    fill: #f1c40f;
  }
  svg {
    height: 7px;
  }
}
.darkmodeBall {
  background-color: $backgroundColorDark;
  border-radius: 50%;
  position: absolute;
  left: -3px;
  height: 9px;
  width: 9px;
  transform: translateX(5px);
  transition: transform 0.2s linear;
}
.darkmodeCheckbox {
  opacity: 0;
  position: absolute;
}

.darkmodeCheckbox:checked + .darkmodeLabel .darkmodeBall {
  transform: translateX(16px);
}

$primaryFontFamily: var(--primary-font-family);
$fontXXSmall: var(--font-xx-extra-small);
$fontXSmall: var(--font-x-extra-small);
$fontSmall: var(--font-small);
$fontSixteen: var(--font-sixteen);
$fontMedium: var(--font-medium);
$fontLarge: var(--font-large);
$fontXLarge: var(--font-x-large);
$fontXXLarge: var(--font-xx-large);
$fontXXXLarge: var(--font-xxx-large);
$secondaryFontFamily: var(--secondary-font-family);

$primaryColor: var(--primary-color);
$primaryDarkColor: var(--primary-dark-color);
$primaryFontColor: var(--primary-font-color);
$dividerColor: var(--divider-color);
$secondaryColor: var(--secondary-color);
$backgroundColorDark: var(--background-color-dark);
$backgroundColor: var(--background-color);
$backgroundBase: var(--background-base);
$highlightBackground: var(--highlight-background);
$light: var(--light);
$dark: var(--dark);
$dangerColor: var(--danger-color);
$undecadeFill: var(--undecade-fill);
$mapboxIcon: var(--mapbox-icon);
$disabledFontColor: var(--disabled-font-color);
$primaryLightColor: var(--primary-light-color)
